import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";

export const Header = ({ navBg }) => {
  const [activeState, setActiveState] = useState(false);
  const [scrollState, setScrollState] = useState(navBg);

  useEffect(() => {
    const handleScroll = () => {
        if (!navBg) {
            if (!document.documentElement.classList.contains("static-nav")) {
                if (window.scrollY >= 75) {
                    setScrollState(true);
                    document.documentElement.classList.add("nav-background");
                } else {
                    setScrollState(false);
                    document.documentElement.classList.remove("nav-background");
                }
            } else {
                setScrollState(true);
                document.documentElement.classList.add("nav-background");
            }
        }
    };

    if (navBg) {
        document.documentElement.classList.add("nav-background");
    }

    window.onscroll = () => handleScroll();

    // return window.removeEventListener("scroll", handleScroll);
  });

  return (
    <StaticQuery
        query={graphql`
        {
            prismic {
                allProgramss {
                  edges {
                    node {
                      body {
                        ... on PRISMIC_ProgramsBodyProgram_list {
                          type
                          label
                          fields {
                            program {
                              ... on PRISMIC_Program {
                                program_name
                                _linkType
                                _meta {
                                  uid
                                }
                              }
                            }
                          }
                        }
                      }
                      _meta {
                        id
                      }
                    }
                  }
                }
              }
        }
        `}
        render={ data => {
            const { prismic } = data;
            const { allProgramss } = prismic;
            const { edges } = allProgramss;
            const fields = edges[0]?.node?.body[0]?.fields;

            const programsList = fields.map(field => <li key={field.program?._meta?.uid}><a href={`/programs/${field?.program?._meta?.uid}`} onClick={() => setActiveState(false)}>{field?.program?.program_name}</a></li>);
            programsList.push([
                <li key="lesswatts"><a href={`/lesswatts`} onClick={() => setActiveState(false)}>Work Out Your Watts</a></li>,
                <li key="meat-the-beetles"><a href={`/meat-the-beetles`} onClick={() => setActiveState(false)}>Meat The Beetles</a></li>,
                <li key="energy-battle-royal"><a href={`/energy-battle-royal`} onClick={() => setActiveState(false)}>Energy Battle Royal</a></li>
            ]);
            return (
                <div className={`container${scrollState ? " background" : ""}`}>
                <a href="/" onClick={() => setActiveState(false)} className={`logo no-effects${activeState ? " menu-is-open" : ""}`}></a>

                <div className={`menu${activeState ? " menu-is-open" : ""}`}>
                    <ul className="nav-links">
                        <li className="nav-item">
                            Programs
                            <div className="programs-container">
                                <ul>
                                    {programsList}
                                </ul>
                            </div>
                        </li>
                        
                        <li className="nav-item"><a href="/about" onClick={() => setActiveState(false)}>About</a></li>
                        <li className="nav-item"><a href="/news" onClick={() => setActiveState(false)}>News</a></li>
                        <li className="nav-item"><a href="/partner" onClick={() => setActiveState(false)}>Partner</a></li>
                        <li className="nav-item"><a href="https://globalinheritance.myshopify.com" target="_blank" onClick={() => setActiveState(false)}>Shop</a></li>
                        <li className="nav-item donate"><a href="/donate" onClick={() => setActiveState(false)}>Donate</a></li>
                        <li className="nav-item signup"><a href="/sign-up" onClick={() => setActiveState(false)}>Join Us</a></li>
                    </ul>
                </div>

                <div className="nav-right">
                    <ul className="nav-links">
                        <li className="nav-item hoverable">
                            Programs
                            <div className="programs-container">
                                <ul>
                                    {programsList}
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item"><a href="/about" onClick={() => setActiveState(false)}>About</a></li>
                        <li className="nav-item"><a href="/news" onClick={() => setActiveState(false)}>News</a></li>
                        <li className="nav-item"><a href="/partner" onClick={() => setActiveState(false)}>Partner</a></li>
                        <li className="nav-item"><a href="https://globalinheritance.myshopify.com" target="_blank" onClick={() => setActiveState(false)}>Shop</a></li>
                        <li className="nav-item donate">
                            <a href="/donate" onClick={() => setActiveState(false)}>
                                <div className="btn small nav-donate">
                                    <span>Donate</span>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item signup">
                            <a href="/sign-up" onClick={() => setActiveState(false)} className="no-effects">
                                <div className="btn small nav-signup">
                                    <span>Join Us</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div onClick={() => setActiveState(!activeState)} className={`hamburger${activeState ? " menu-is-open" : ""}`}>
                    <div className="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            )
        }}
    />
  )
}

export default Header;